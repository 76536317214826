// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import CaseStudy from '../../../components/CaseStudy'
import SEO from '../../../components/seo'
import messages from '../../../messages/pageTitles.lang'
import dimensionMessages from '../../../messages/dimensions.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'

import baseStyles from './aldiana.scss'
import baseCaseMessages from '../../../components/CaseStudy/CaseStudy.lang'
import caseMessages from '../../../components/CaseStudy/messages/Aldiana/Aldiana.lang'

/* eslint-disable max-len */
const heroBgDesktop = (
  <StaticImage src="../../../images/casestudy/herobgdesktop.jpg" alt="" objectFit="cover" className="image" />
)
const heroImgDesktop = (
  <StaticImage
    src="../../../images/casestudy/aldiana/aldiana-mainimage@2x.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const welcomeLogo = (
  <StaticImage src="../../../images/casestudy/aldiana/Logo.png" alt="" objectFit="contain" className="image" />
)
const welcomeImg = (
  <StaticImage
    src="../../../images/casestudy/aldiana/aldiana-welcome@2x.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const infoImg = (
  <StaticImage
    src="../../../images/casestudy/aldiana/aldiana-target-group@2x.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const func1Img = (
  <StaticImage
    src="../../../images/casestudy/aldiana/aldiana-features@2x.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const func2Img = (
  <StaticImage
    src="../../../images/casestudy/aldiana/aldiana-performance@2x.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const solutionImg = (
  <StaticImage
    src="../../../images/casestudy/aldiana/aldiana-bewotec@2x.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const brandingLogo = (
  <StaticImage
    src="../../../images/casestudy/aldiana/Logo.png"
    alt=""
    objectFit="contain"
    className="image image-brand"
  />
)
const colorsImg = (
  <StaticImage src="../../../images/casestudy/aldiana/Colors.png" alt="" objectFit="contain" className="image" />
)
const recognitionImg = (
  <StaticImage
    src="../../../images/casestudy/aldiana/aldiana-branding-business-cards.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const techImg = (
  <StaticImage src="../../../images/casestudy/aldiana/tech.png" alt="" objectFit="contain" className="image" />
)
const feedbackBrandes = (
  <StaticImage
    src="../../../images/casestudy/aldiana/client-brandes.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const feedback01 = (
  <StaticImage src="../../../images/casestudy/aldiana/customer-01.png" alt="" objectFit="contain" className="image" />
)
const feedback02 = (
  <StaticImage src="../../../images/casestudy/aldiana/customer-02.png" alt="" objectFit="contain" className="image" />
)
const feedback03 = (
  <StaticImage src="../../../images/casestudy/aldiana/customer-03.png" alt="" objectFit="contain" className="image" />
)
/* eslint-enable max-len */

import { projectsList } from '../../../components/CaseStudy/helpers'

type Props = {
  ...StyleProps
}

const data = {
  // link: 'https://www.aldiana.com/',
  name: 'Aldiana',
  videoId: 'trb5937NxYc',
  videoTitle: caseMessages.videoTitle,
  heroTeaser: {
    bg: {
      imageDesktop: heroBgDesktop
    },
    img: heroImgDesktop,
    title: projectsList.aldiana.title,
    description: projectsList.aldiana.description
  },
  facts: [
    {
      title: baseCaseMessages.challenge,
      text: caseMessages.fact1Text,
      color: 'tertiary',
      icon: 'icon-eye'
    },
    {
      title: baseCaseMessages.solution,
      text: caseMessages.fact2Text,
      color: 'secondary',
      icon: 'icon-board'
    },
    {
      title: baseCaseMessages.approach,
      text: caseMessages.fact3Text,
      color: 'accent',
      icon: 'icon-devices'
    }
  ],
  welcome: {
    logo: welcomeLogo,
    text: caseMessages.welcomeText,
    img: welcomeImg
  },
  info: {
    img: infoImg,
    title: caseMessages.infoTitle,
    list: [caseMessages.infoItem1, caseMessages.infoItem2, caseMessages.infoItem3]
  },
  stats: [
    {
      message: caseMessages.stat1Message,
      value: caseMessages.stat1Value,
      color: 'primary',
      icon: 'icon-goblet'
    },
    {
      number: 10,
      positive: true,
      value: caseMessages.stat2Value,
      color: 'tertiary',
      icon: 'icon-house'
    },
    {
      number: 46,
      positive: true,
      numberDimension: dimensionMessages.dimensionYears,
      value: caseMessages.stat3Value,
      color: 'secondary',
      icon: 'icon-star-filled'
    },
    {
      number: 92,
      positive: true,
      numberDimension: dimensionMessages.dimensionMillion,
      value: caseMessages.stat4Value,
      color: 'accent',
      icon: 'icon-euro'
    }
  ],
  functionalitiesTitle: baseCaseMessages.functionalitiesTitle,
  functionalities: [
    {
      title: caseMessages.functionality1Title,
      list: [
        caseMessages.functionality1Item1,
        caseMessages.functionality1Item2,
        caseMessages.functionality1Item3,
        caseMessages.functionality1Item4
      ],
      img: func1Img
    },
    {
      title: caseMessages.functionality2Title,
      list: [caseMessages.functionality2Item1, caseMessages.functionality2Item2, caseMessages.functionality2Item3],
      img: func2Img
    }
  ],
  roadmap: [
    {
      title: baseCaseMessages.roadmapWorkshop,
      timeValue: '',
      timeMeasure: '',
      color: 'primary',
      icon: 'icon-flag'
    },
    {
      title: baseCaseMessages.roadmapConception,
      timeValue: '',
      timeMeasure: '',
      color: 'tertiary',
      icon: 'icon-quality'
    },
    {
      title: baseCaseMessages.roadmapDevelopment,
      timeValue: '',
      timeMeasure: '',
      color: 'secondary',
      icon: 'icon-memory'
    },
    {
      title: baseCaseMessages.roadmapRelease,
      timeLabel: '',
      timeValue: '',
      timeMeasure: '',
      color: 'accent',
      icon: 'icon-heart-filled'
    }
  ],
  solution: {
    title: caseMessages.solutionTitle,
    solutionTextTitle: caseMessages.solutionTextTitle,
    text: caseMessages.solutionText,
    img: solutionImg
  },
  brand: {
    title: baseCaseMessages.brandsTitle,
    logo: {
      title: baseCaseMessages.logoTitle,
      list: [caseMessages.brandLogoItem1, caseMessages.brandLogoItem2, caseMessages.brandLogoItem3],
      img: brandingLogo
    },
    colors: {
      title: baseCaseMessages.colorsTitle,
      list: [caseMessages.brandColorsItem1, caseMessages.brandColorsItem2, caseMessages.brandColorsItem3],
      img: colorsImg
    },
    recognition: {
      title: baseCaseMessages.recognitionTitle,
      text: caseMessages.brandRecognitionText,
      img: recognitionImg
    },
    tech: {
      title: baseCaseMessages.techTitle,
      list: [
        baseCaseMessages.techPerfomance,
        baseCaseMessages.techScalable,
        baseCaseMessages.techFlexible,
        baseCaseMessages.techArt
      ],
      img: techImg
    },
    design: {
      title: baseCaseMessages.designTitle,
      text: baseCaseMessages.designText,
      list: [baseCaseMessages.designItemInnovative, baseCaseMessages.designItemDevices],
      img: projectsList.aldiana.overviewImage2
    }
  },
  feedback: [
    {
      text: caseMessages.feedbackText,
      from: caseMessages.feedbackFrom,
      img: feedbackBrandes
    }
  ],
  reviews: [
    {
      img: feedback01,
      name: 'Peter K.',
      from: 'Tripadvisor',
      title: 'Once and forever',
      rating: [1, 2, 3, 4, 5],
      // eslint-disable-next-line max-len
      text: 'This is where the Aldiana slogan "Holiday among friends" is lived, with fun and joy and never seems to be played or put on!'
    },
    {
      img: feedback02,
      name: 'Dennis',
      from: 'Tripadvisor',
      title: 'Wonderful club holiday',
      rating: [1, 2, 3, 4, 5],
      // eslint-disable-next-line max-len
      text: 'A dreamlike club for club holiday lovers! Here everything is thought of, whether young or old, one feels wonderfully picked up.'
    },
    {
      img: feedback03,
      name: 'Maggi3177',
      from: 'Tripadvisor',
      title: 'Top vacation by friends!',
      rating: [1, 2, 3, 4, 5],
      text: 'If you want to eat well, meet relaxed people, celebrate and have a lot of fun, go to this club!'
    }
  ] // ,
  // check: {
  //   img: projectsList.aldiana.marketingImage,
  //   title: baseCaseMessages.checkTitle,
  //   text: baseCaseMessages.checkText
  // }
}

const CaseAldiana = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />} styles={styles.root}>
      <SEO
        title={formatMessage(messages.TitleCaseStudyAldiana)}
        description={formatMessage(metaDescription.CaseStudyAldianaDescription)}
        lang={locale}
      />
      <CaseStudy data={data} styles={styles.case} />
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseAldiana)
